import useLocalStorage from "use-local-storage";

import "./App.css";
import { FaMoon, FaSun } from "react-icons/fa";

function App() {
  const defaultDark = window.matchMedia("(prefers-color-scheme: dark)").matches;

  const [theme, setTheme] = useLocalStorage(
    "theme",
    defaultDark ? "dark" : "light"
  );

  const switchTheme = () => {
    const newTheme = theme === "light" ? "dark" : "light";
    setTheme(newTheme);
  };

  return (
    <div theme={theme}>
      <div className="app">
        <h1>Easy Light & Dark Mode</h1>
        <p>
          An easy light/dark theme using CSS variables, data attributes and
          useLocalStorage hook.
        </p>

        <label className="label">
          <FaSun className="icon-light" />
          <button className="checkbox" onClick={switchTheme}>
            <span class="checkbox-toogle"></span>
          </button>
          <FaMoon className="icon-dark" />
        </label>

        <a
          className="app-link"
          href="https://github.com/sarahcssiqueira/easy-light-dark-theme"
          target="_blank"
          rel="noopener noreferrer"
        >
          Source Code
        </a>
      </div>
    </div>
  );
}

export default App;
